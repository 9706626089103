<template>
<h1>Liste des inscrits</h1>
  <Liste :liste_personnes="personnes" />
</template>

<script>
import Liste from "./components/Liste.vue";

export default {
  name: "App",
  components: {
    Liste,
  },
  data() {
    return {
      personnes: [
        { prenom: "Jean", nom: "Bréhat", age: "20" },
        { prenom: "Léa", nom: "Prijean", age: "25" },
        { prenom: "Chris", nom: "Martin", age: "38" },
        { prenom: "Pierre", nom: "Petit", age: "18" },
      ],
    };
  },
};
</script>

<style>

</style>
