<template>


<table border>
    <thead>
        <tr>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Age</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="(p, index) in liste_personnes" :key="index">
            <td>{{p.prenom}}</td>
            <td>{{p.nom}}</td>
            <td>{{p.age}}</td>
        </tr>
    </tbody>
</table>
</template>

<script>
    export default {
  name: 'Liste',
  props: ["liste_personnes"]
    }
</script>

<style>
th {
    background: lightgrey;
    font-size: 140%;
}
td {
    width: 10rem;
    text-align: center;
}
</style>